@use '../helpers/variables' as *;
.navigation {
  ul {
    display: flex;
    align-items: center;

    > li {
      font-weight: $fw-bold;

      &:not(:first-of-type) {
        @media (min-width: $screenSm){
        &::before {
          content: '-';
          color: $color1;
          font-weight: $fw-bold;
          margin: 0 1rem;
        }
        }
      }

      > span {
        font-weight: $fw-normal;
      }

      > a,
      > button {
        color: $color2;
        font-size: 1.6rem;
        font-weight: $fw-bold;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}