@use '../helpers/variables' as *;
main.teams{
    background-image: url('../../images/teams-bg.png');
    background-size: cover;
    .wrapper-teams{
        width: 100%;
        padding: 6.4rem 3.2rem 3.2rem 3.2rem ;
        //position: absolute;
        //bottom: 10vh;
        //left: 50%;
        //transform: translateX(-50%);
        min-width: initial;
        height: initial;
        &>h2{
            font-size: 2.6rem;
            position: relative;
            //margin-top: 6.4rem;
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 4rem;
            &:after{
                content: '';
                width: 3rem;
                height: .24rem;
                background-color: $color2;
                position: absolute;
                bottom: -2rem;
                left: 0;
            }
        }
        .team-status{
            display: flex;
            justify-content: space-between;
            padding: 0rem 0 1.6rem 0;
            span{
                font-weight: 200;
                font-size: 1.6rem;
                line-height: 2rem;
                &+span{
                    font-style: italic;
                    color: #cccccc;
                }
            }
        }
    }
    .card{
        /*width: calc(100vw - 64px);
        position: absolute;
        bottom: 20vh;
        left: 50%;
        transform: translateX(-50%);*/
        width: 100%;
        min-width: initial;
        height: initial;
        .top{
            display: flex;
            gap: .5rem;
            color: #444;
            margin-bottom: 4rem;
            h2{
                font-size: 1.8rem;
                line-height: 2.4rem;
                position: relative;
                &:after{
                    content: '';
                    width: 3rem;
                    height: .24rem;
                    background-color: $color2;
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                }
            }
            img{
                width: 5rem;
                min-width: 5rem;
            }
        }
        
        .content{
            color: #444;
            p{
                font-size: 1.4rem;
                line-height: 2rem;
                //padding-right: 4rem;
            }
        }
    }
    .btn{
        background: $color2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 1.5rem;
        gap: 1.6rem;
        color: #fff;
        box-shadow: 0px 6.09375px 9.75px rgba(0, 0, 0, 0.12);
        border-radius: 48.75px;
        width: 100%; //calc(100vw - 64px);
        max-width: 100%;//calc(100vw - 64px);
        margin-top: 2rem;
        font-weight: 700;
        font-size: 1.95rem;
        line-height: 2.9rem;
        .icon-teams{
            //background-image: url('../../images/teams.svg');
            background-color: #fff;
            -webkit-mask: url(../../images/teams.svg) no-repeat center;
            mask: url(../../images/teams.svg) no-repeat center;
            width: 28px;
            height: 28px;
        }
    }

    .oneTeam{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .7rem 1.6rem;
        gap: .8rem;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px rgba(68, 68, 68, 0.2);
        border-radius: 8px;
        &.big{
            padding: 2rem 1.6rem;
            border-radius: 8px 8px 0px 0px;
            border-bottom: 2px solid $color2;
        }
        
        &.notMyTeam{
            border-bottom: 2px solid #137E84;
        }
        span{
            color: #222;
            &.nick{
                font-family: 'SantanderHeadline';
                font-weight: 700;
                font-size: 1.7rem;
                font-size: 1.8rem;
                line-height: 2.4rem;
            }
            &.hits{
                position: relative;
                padding-left: 15px;
                &:before{
                    content:'';
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    background-image: url(../../images/hits.svg);
                    top: 3px;
                    left:0;

                }
                &:after{
                    content:'';
                    position: absolute;
                    width: 1px;
                    height: 20px;
                    background-color: #ccc;
                    top:0;
                    right: -8px;
                }
            }
            &.time{
                position: relative;
                padding-left: 30px;
                &:before{
                    content:'';
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    background-image: url(../../images/timer.svg);
                    top: 3px;
                    left: 15px;

                }
            }
            &.hits, &.time{
                font-weight: 200;
                font-size: 1.4rem;
            }
            &.empty{
                font-family: 'SantanderHeadline';
                color: #cccccc;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.4rem;
            }
        }
        .left{
            svg{
                height: 4rem;
                width: 4rem;
            }
        }
        .center{
            width: 70%
        }
        .right{
            width: 15%;            
            span{
                font-family: 'SantanderHeadline';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: right;
                color: $color2;// #CC0000;
            }
            &.manager{
                span{
                    font-weight: 400;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: #444444;
                }
                img{
                    margin-left: 2rem;
                }
            }
            .bottom{
                span{
                    font-weight: 400;
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                }
                img{
                    width: 1.3rem;
                }
            }
            &.right25{
                width: 30%;
                .top{
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
    .memberOf{
        margin-top: 5.8rem;
        p{
            font-weight: 200;
            margin-bottom: 1.6rem;
        }
    }
    .theteam-top{
        background:  #222;
        display : flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        span{
            font-family: 'SantanderHeadline';
            font-style: normal;
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.2rem;
            color: $color2;
        }
        .left{
            display: flex;
            align-items: center;
            gap: 1.4rem;
            span{
                font-family: 'SantanderHeadline';
                font-weight: 700;
                font-size: 2.4rem;
                line-height: 2.8rem;
                color: #fff;
            }
        }
        
    }    
    &.the-team{
        .wrapper-teams{
            h2{
                font-weight: 700;
                font-size: 3.2rem;
                line-height: 3.8rem;
            }
            p{
                margin-top: 3rem;
                font-weight: 200;
                font-size: 1.6rem;
                line-height: 2rem;
                margin-bottom: 1.6rem;
                &.minusMargin{
                    //margin: 0 -1rem;
                    margin: 1.6rem 0;
                    text-align: center;
                }
            }
            .users{
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
            }
        }
    }

    .modal{
        pointer-events: none;
        background-color: transparent;
        background: rgba(118, 140, 155, 0.4);
        backdrop-filter: blur(4px);
        .modalWrapper{
             .modalContent{
                background: #fff;
                //max-height: 50vh;
                flex-grow: initial;
                margin-top: 20vh;
                padding: 2.4rem;
                pointer-events: all;
                h2{
                    color: #222;
                    font-weight: 700;
                    font-size: 3.2rem;
                    line-height: 3.8rem;
                    position: relative;
                    margin-bottom: 4rem;
                    &:after{
                        content: '';
                        width: 3rem;
                        height: .24rem;
                        background-color: $color2;
                        position: absolute;
                        bottom: -1.4rem;
                        left: 0;
                    }
                }
                p{
                    color: #222;
                }
                .btn{
                    background: $color2;
                    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
                    border-radius: 40px;
                }
             }
        }
    }
}
