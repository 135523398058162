@use '../helpers/variables' as *;
main.profile{
    background-image: url('../../images/teams-bg.png');
    background-size: cover;
    .wrapper-profile{
        width: 100%;
        padding: 6.4rem 3.2rem 3.2rem 3.2rem ;
        //position: absolute;
        //bottom: 10vh;
        //left: 50%;
        //transform: translateX(-50%);
        min-width: initial;
        height: initial;
        &>h2{
            font-size: 2.6rem;
            position: relative;
            //margin-top: 6.4rem;
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 4rem;
            &:after{
                content: '';
                width: 3rem;
                height: .24rem;
                background-color: $color2;
                position: absolute;
                bottom: -2rem;
                left: 0;
            }
        }
    }
}