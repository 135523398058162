main{
    g[seriesName="Borramex2"],
    .apexcharts-legend{
        display: none;
    }
    g{
        .apexcharts-xaxis{
            position: relative;
            text{
                font-family: Halcom;
                font-size: 10px;
                fill: #000;
            }
            text:nth-child(2){
                position: absolute;
                transform: rotate(90deg);
                transform-origin: 30% -2%;/*60px -10px;*/
                @media (min-width: 450px){
                    transform-origin: 30% -2%;
                }
                @media (min-width: 550px){
                    transform-origin: 24% -2%;
                }
                @media (min-width: 650px){
                    transform-origin: 20% -2%;
                }
                @media (min-width: 768px){
                    transform-origin: 30% -2%;
                }
                @media (min-width: 1024px){
                    transform-origin: 26% -2%;/*88px -10px;*/
                }
                @media (min-width: 1200px){
                    transform-origin: 20% -2%;/*88px -10px;*/
                }
                @media (min-width: 1600px){
                    transform-origin: 102px -6px;
                }
            }
            text:nth-child(4){
                position: absolute;
                transform: rotate(-90deg);
                transform-origin: -36% -4%;/*-75px -21px;*/
                @media (min-width: 450px){
                    transform-origin: -38% -2%;
                }
                @media (min-width: 550px){
                    transform-origin: -32% -2%;
                }
                @media (min-width: 650px){
                    transform-origin: -24% -2%;
                }
                @media (min-width: 768px){
                    transform-origin: -38% -2%;
                }
                @media (min-width: 1024px){
                    transform-origin: -30% -4%;/*-102px -21px;*/
                }
                @media (min-width: 1200px){
                    transform-origin: -24% -4%;
                }
                @media (min-width: 1600px){
                    transform-origin: -113px -16px;
                }
            }
        }
    }
}