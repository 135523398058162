@use '../helpers/variables' as *;

main.welcome{
    background-image: url('../../images/welcome-bg.png');
    background-size: cover;
    padding: 22.4rem 2.4rem;
    .wrapper{
        background-color: #fff;
        padding: 2rem;
        .top{
            display: flex;
            gap: 2rem;
            justify-content: space-between;
        }
    }
    h2{
        font-size: 2.6rem;
        position: relative;
        //margin-top: 6.4rem;
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.2rem;
        color: #222;
        span{
            font-size: 1.2rem;
            display: block;
            line-height: 1.8rem;
        }
        &:after{
            content: '';
            width: 3rem;
            height: .24rem;
            background-color: $color2;
            position: absolute;
            bottom: -1.4rem;
            left: 0;
        }
    }
    img{
        width: 4rem;
    }
    p{
        font-weight: 200;
        margin: 2rem 0;
        color: #222;
    }
    button{
        background: $color2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem 1.5rem;
        gap: 1.6rem;
        color: #fff;
        box-shadow: 0px 6.09375px 9.75px rgba(0, 0, 0, 0.12);
        border-radius: 48.75px;
        width: 100%; //calc(100vw - 64px);
        max-width: 100%;//calc(100vw - 64px);
        margin-top: 2rem;
        font-weight: 700;
        font-size: 1.95rem;
        line-height: 2.9rem;
    }
}