@use '../helpers/variables' as *;
main{
  background-color: #222222;
  min-height: calc(100vh - 68px);
  h2{
    margin: 0;
  }
  .content{
    .title{
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 3.8rem;
      position: relative;
      margin: 0;
      margin-bottom: 2.8rem;
      &:after{
        content: '';
        position: absolute;
        bottom: -1.4rem;
        left: 0;
        width: 25px;
        height: 2px;
        background-color: #EC0000;
      }
    }
  }
  
}
.home {
  padding-top: 4rem;
  .content {
    padding: 2rem 0;
    @media (min-width: $screenSm){
      display: flex;
    }

    .colRight {
      .btn {
        display: inline-block;
        margin: 4rem 0;
      }
    }

    /*.title {
      font-size: 2rem;
      line-height: 2.8rem;
      max-width: 340px;
      @media (min-width: $screenSm){
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }*/

    .desc {
      line-height: 2.8rem;
      margin: 2rem 0;
    }
  }

  .imgIntro {
    width: 100%;
    height: 22.4rem;
    object-fit: cover;
    display: block;
    @media (min-width: $screenSm){
      width: 43.4rem;
      height: 60.4rem;
    }
  }
}