@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;
.modal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2000;
  @media (min-width: $screenSm) {
    background-color: rgba($color1, 0.9);
  }

  .btnClose {
    pointer-events: all;
    position: absolute;
    top: 2rem;
    right: 2rem;

    [class^="icon-"] {
      &::before {
        font-size: 2rem;
      }
    }
  }

  .modalWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8rem 3rem 4rem;
    text-align: center;

    @media (min-width: $screenSm) {
      max-width: 55.6rem;
      max-height: 49.2rem;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
    }
  }

  .modalHeader {
    margin: .5rem 0;
    @media (min-width: $screenSm) {
      padding: 0 5%;
    }

    .title {
      font-size: 2.2rem;
      line-height: 2.8rem;
      font-weight: $fw-bold;
      .icon-ok{
        color: #5B19C4;
        margin-right: 12px;
      }
    }
  }

  .modalContent {
    flex-grow: 1;
    margin: 0 0;
    @media (min-width: $screenSm) {
      padding: 0 5%;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
      margin: 1rem 0;
    }
  }

  .modalFooter {
    pointer-events: all;
    margin: 0 ;
    padding: 5px 5px 15px 5px;
    @media (min-width: $screenSm) {
      display: flex;
      justify-content: center;
    }

    .btn {
      margin: 1rem 0;
      @media (min-width: $screenSm) {
        margin: 1rem;
      }
    }
  }

  &.modalSendQuiz {
    .modalWrapper {
      text-align: left;
      @media (min-width: $screenSm) {
        width: 80%;
        max-width: 94.8rem;
        max-height: 50rem;
      }
    }

    .formGroup {
      margin-top: 2rem;

      label {
        font-weight: $fw-bold;
      }
    }
  }
  &.modalDownload{
    .modalWrapper{      
      @media (min-width: 720px) {
        min-width: 600px;
      }
    }
    .downloadWrapper{
      display: flex;
      flex-direction: column;
      @media (min-width: 950px) {
        flex-direction: row;
      }      
      .left{
        width: 100%;
        @media (min-width: 950px) {
          width: 35%;
        }
      }
      .right{
        width: 100%;
        @media (min-width: 950px) {
          width: 65%;
        }
        p{
          text-align: left;
        }
        .modalFooter {
          display: flex;
          justify-content: left;
        }
      }
    }
  }
  &.modalOK{
    .modalWrapper{
        padding-top: 2em;
        .modalContent{
            display: flex;
            flex-direction: column;
            @media (min-width: 950px) {
              flex-direction: row;
            }
            gap: 30px;
            div{    
                &.left{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: 950px) {

                    }
                    img{
                        max-width: 146px;
                        height: auto;
                    }
                }                
                
                h2{
                    text-align: left;
                }
                p{
                    text-align: left;
                }
            }
        }
    }
  }
  &.modalF1{
    pointer-events: none;
    background-color: transparent;
    background: rgba(118, 140, 155, 0.4);
    backdrop-filter: blur(4px);
    .modalWrapper{
         .modalContent{
            background: #fff;
            //max-height: 50vh;
            flex-grow: initial;
            margin-top: 20vh;
            padding: 2.4rem;
            pointer-events: all;
            h2{
                color: #222;
                font-weight: 700;
                font-size: 3.2rem;
                line-height: 3.8rem;
                position: relative;
                margin-bottom: 4rem;
                &:after{
                    content: '';
                    width: 3rem;
                    height: .24rem;
                    background-color: $color2;
                    position: absolute;
                    bottom: -1.4rem;
                    left: 0;
                }
            }
            p{
                color: #222;
            }
            .btn{
                background: $color2;
                box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
                border-radius: 40px;
                color: #fff;
            }
         }
         .modalFooter{
            background: #fff;
         }
    }
  }
}