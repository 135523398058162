@use '../helpers/variables' as *;
.modalWrapper{
    
    
    .modalCustomHeader{
        margin-top:30vh;
        width: 100%;
        
        background: #fff;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;

        .btnCloseCorner{
            pointer-events: all;
            padding: 1.5rem 1.5rem 0rem 1.5rem;
        }
    }
    .modalCustomContent{
       display:flex;
       justify-content: space-between;
       background: #fff;
       flex-direction: row;
       //max-height: 50vh;
       flex-grow: initial;
       padding: 2.4rem 2.4rem 1rem 2.4rem;
       pointer-events: all;
       h2{
           text-align: left;
           color: #222;
           font-weight: 700;
           font-size: 3.2rem;
           line-height: 3.8rem;
           position: relative;
           margin-bottom: 4rem;
           &:after{
               content: '';
               width: 3rem;
               height: .24rem;
               background-color: $color2;
               position: absolute;
               bottom: -1.4rem;
               left: 0;
           }
       }
       .icon{
        margin-top:15px;
            display:flex;
       }
    }
}

.modal-redline{
    width: 30px;
        height: 15px;
        border-top: #EC0000 3px solid;
}
.btn-modal{
    padding: 10px 30px 10px 30px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    font-size:16px;
    line-height: 24px;}
.btn-close{
    background: #F5F9FB;
    border: $color2 1px solid;
    color:$color2;
    font-weight: 400;
}

.btn-continue{
    margin-left:15px;
    background: $color2;
    color:white;
    font-weight: 700;
}