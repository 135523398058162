@use '../helpers/variables' as *;
.questions {
    position: relative;
    height: 100%;
    padding: 0 0;
    background-color: $color4;
}

.questions-title {
    display: flex;
    flex-wrap: initial;
    justify-content: space-between;
    padding: 5px 17px;
    background-color: #222222;
    .left {
        display: flex;
        align-items: center;
        gap: 12px;
        .casco{
            display: flex;
            gap: 1rem;
            align-content: center;
        }
        .gp-name {
            font-weight: 400;
            font-style: italic;
        }
    }
}

.headerQuestion {
    margin: 16px 16px 0 16px;
    display: flex;
    flex-direction: row;
}

.numberContainer {
    display: flex;
    flex-direction: row;
    height: 46px;
    background-color: $color13;
    flex-grow: 1.4;
    padding: 5px 10px 5px 10px;
    text-align: center;
}

.circleContainer>div {
    width: 30px;
    height: 100%;
}

.circleContainer {
    height: 38px;
    /*border-bottom: #EC0000 3px solid;*/
}


/*.numberContainer>div>svg>path:first-of-type{
    stroke:$color4;
  }*/

.txtOf {
    flex: 1;
    font-weight: 700;
    margin-left: 0px;
}

.chronoContainer {
    display: flex;
    margin-top:5px;
    height: 40px;
    flex-grow: 4;
    padding: 2px 5px 0 5px;
    justify-content: flex-end;
    align-content: baseline;
}
.pauseContainer {
    display: flex;
    height: 40px;
    flex-grow: 1;
    padding: 10px 5px 0 5px;
    justify-content: flex-end;
    align-content: baseline;
}

.question {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../images/question-bg.png'), url('../../images/question-bg.png');
    min-height: 100vh;
    padding-top:28px;
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: cover;
}

.containerQuestion {
    margin: 0 16px 16px 16px;
    height: 100%;
    color: $color14;
    font-family: $font1;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
}

.pregunta {
    background-color: $color13;
    padding: 16px 16px 16px 16px;
    font-weight: 700;
}

.opcion {
    padding: 15px 16px;
    border-width: 1px 0px;
    border-color: $color5;
    border-style: solid;
}

.color-opcion {
    background-color: $color1;
}

.color-opcion-correcta {
    background-color: #E1F1E2;
}

.color-opcion-erronea {
    background-color: #FEE5E5;
}

.color-opcion-team-radio {
    background-color: #EC0000;
}

.numberContainer {
    color: $color9;
}

.txtOf {
    margin: 5px 0 0 0;
}


/*estilos para cuando sustituye la opción por lo del team radio*/

.opcion-comodin {
    display: flex;
    flex-direction: row;
}

.icon-team-opcion-comodin {
    flex: 1;
}

.texto-team-opcion-comodin {
    flex: 6;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.finish {
    font-weight: 400;
    font-size: 1.8rem;
}

.footer-finish {
    position: absolute;
    width: 100%;
    bottom: 5vh;
}

.bt-chances {
    display: flex;
    margin: 62px 36px 0px 36px;
    padding: 10px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background-color: #EC0000;
    border-radius: 40px;
}

.text-bt-chances {
    vertical-align: middle;
    height: 26.5px;
}

.icon-bt-chances {
    margin-left: 10px;
}

.quiz .finish-wrapper {
    display: flex;
    flex-direction: column;
    //background: rgba(255, 255, 255, 0.9);
    //border-radius: 4px;
    width: calc(100vw - 32px);
    position: absolute;
    bottom: 25vh;
    left: 50%;
    transform: translateX(-50%);
    //padding: 16px;
    .left{
        img{
            width: 3rem;
        }
    }
    .btn{
        color: #fff;
        background-color: $color2;
        border-radius: 4rem;
        margin: 2rem 0;
    }
}

.finish-metrics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 3rem;
    background: rgba(255, 255, 255, 0.9);
    .finish-casco{
        width: 4rem;
        height: 4rem;
    }
}

.finish-header {
    display: flex;
    flex-direction: row;
    .finish-casco {
        svg{
            height: 4rem;
            width: 4rem;
        }
    }
    .text-position {
        margin-left: 15px;
        color: #222222;
        font-weight: 700;
        line-height: 3.9rem;
        font-size: 3rem;
        vertical-align: bottom;
    }
}

.finish-content {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #222222;
    margin-top: 10px;
}

.finish-icon-metrics {
    vertical-align: middle;
    margin-right: 5px;
}

.ml-7 {
    margin-left: 7px;
}

.border-right-gray {
    padding-right: 7px;
    border-right: 1px solid #CCC;
}

.red-underline {
    border-bottom: #EC0000 2px solid;
}

.link-gp-ranking {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    color: #EC0000;
    vertical-align: middle;
    font-size: 11px;
    line-height: 22px;
    font-weight: 400;
}

.text-link-gp-ranking {
    line-height: 15px;
    vertical-align: middle;
    margin-right: 7px;
}