@use '../helpers/variables' as *;

.rankings{
    
    background-color: #F5F9FB;  
    .ranking-container{
        padding: 8px 16px;
        min-height: 100vh;
        position: relative;
        .blur{
            width: 100%;
            height: 100%;
            z-index: 5;
            pointer-events: none;
            background: rgba(118, 140, 155, 0.4);
            backdrop-filter: blur(4px);
            position: absolute;
            left:0;
            top:0;
            width:100vw;
        }
    }  
    .ranking{        
        display: flex;
        flex-direction: column;
        background-color: #fff;  
        gap: 2px;
    }      

    .position{
        display: flex;
        border-bottom: 1.21875px solid #CEDEE7;
        .order{
            width: 15%;
            min-width: 78px;
            background-color: #CEDEE7; //$color2;
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 8px 0;
            span{
                font-size: 2.8rem;
                line-height: 3.9rem;
                font-weight: 500;
                font-family: 'SantanderHeadline', 'Formula1';
            }
        }
        &.first{
            border-bottom: 2px solid $color2;
            position: relative;
            .order{
                background-color: $color2;
            }
            &:after{
                content:'';
                position: absolute;
                width: 23px;
                height: 29px;
                background-image: url(../../images/trophy.svg);
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
        
        .user-info{
            width: 85%;
            display: flex;
            padding: 8px 13px;
            gap: 20px;
            //border: 0px solid #222222;
            .bottom{
                display: flex;
                gap: 10px;
            }
            span{
                color: #222;
                &.nick{
                    font-weight: 700;
                    font-size: 1.7rem;
                }
                &.hits{
                    position: relative;
                    padding-left: 15px;
                    &:before{
                        content:'';
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        background-image: url(../../images/hits.svg);
                        top: 3px;
                        left:0;

                    }
                    &:after{
                        content:'';
                        position: absolute;
                        width: 1px;
                        height: 20px;
                        background-color: #ccc;
                        top:0;
                        right: -6px;
                    }
                }
                &.time{
                    position: relative;
                    padding-left: 15px;
                    &:before{
                        content:'';
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        background-image: url(../../images/timer.svg);
                        top: 3px;
                        left:0;

                    }
                }
                &.hits, &.time{
                    font-weight: 200;
                    font-size: 1.4rem;
                }
            }
        }
        .left{
            svg{
                width: 4rem;
                height: 3.9rem;
            }
        }
        .right{
            display: flex;
            justify-content: center;
            width: 10%;
            .trophy{
                position: relative;
                &:before{
                    content:'';
                    position: absolute;
                    width: 23px;
                    height: 29px;
                    background-image: url(../../images/trophy.svg);
                    top: 3px;
                    left:0;

                }
            }
        }
    }
    

    .ranking-header{
        display: flex;
        width: 100%;
        background-color: #fff;
        &>div{
            width: 33.333%;
            position: relative;
            &.active{
                border-bottom: 2px solid $color2;
            }
            button{
                width: 100%;
                text-align: center;
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 2.9rem;
                padding: 7px 0;
            }
        }
        .GP-selector{
            width: 100%; //33%;
            z-index: 15;
            background-color: #fff;
            position: absolute;
            top: 45px;
            ul{
                padding: 5px 0;
                li{
                    padding: 0 5px;
                    button{
                        &.active{
                            background-color:#CC0000;                            
                            color: #fff;
                            border-radius: 4px;
                            padding: 0;
                        }
                    }
                }
            }
        }
        .gps{
            &>button{
                position: relative;
                &:after{
                    content:'';
                    position: absolute;
                    top: 50%;//15px;
                    right: 10px;
                    width: 10px;
                    height: 6px;
                    background-image: url('../../images/select-arrow.svg');
                    background-repeat: no-repeat;
                    transform: translateY(-50%);
                }
                &.open{
                    &:after{
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
    }

    .my-ranking{
        position: fixed;
        bottom: 70px;
        left: 0;
        width: 100%;
        z-index: 15;
        background-color: #fff;
        filter: drop-shadow(0px 1.21875px 9.75px rgba(0, 0, 0, 0.38));
        border-bottom: 0;
        .order{
            background: #768E9B;
        }
    }
    .myTeams{
        position:fixed;
        bottom: 70px;
        left: 0;
        width: 100%;
        z-index: 15;
        
        border-bottom: 0;
        .my-ranking{
            background-color: #fff;
            filter: drop-shadow(0px 1.21875px 9.75px rgba(0, 0, 0, 0.38));
            position: initial;
            &.not-my-team{
                width: 90%;
                margin-left: 5%;
                .order{
                    background-color: #B2C8D3;
                }                
            }
        }
    }
}