// Columns & gutter
$numCols: 12;
$numGutter: 10;

@mixin cols($cols) {
  width: percentage(calc($cols / $numCols));
  padding-left: $numGutter * .1rem;
  padding-right: $numGutter * .1rem;
}

@mixin colsNoGutter($cols) {
  width: percentage(calc($cols / $numCols));
}

@mixin colsOffset($cols) {
  margin-left: percentage(calc($cols / $numCols));
}


// Clearfix
@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

// centrar verticalmente y/o horizontalmente
@mixin center($xy:xy) {
  @if $xy == xy {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    position: absolute;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  @else if $xy == y {
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

// posición secciones
@mixin positionNav($pos){
  @if $pos == bottomRight {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
  }
  @else if $pos == bottomLeft {
    right: 50%;
    top: 50%;
    bottom: auto;
    left: auto;
  }
  @else if $pos == topRight {
    left: 50%;
    top: auto;
    bottom: 50%;
    right: auto;
  }
  @else if $pos == topLeft {
    left: auto;
    top: auto;
    bottom: 50%;
    right: 50%;
  }
}

// custom Scrollbar
@mixin customScrollbar ($axis: 'vertical') {
  @if ($axis == 'vertical') {
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: .5rem;
    }
  }

  @if ($axis == 'horizontal') {
    &::-webkit-scrollbar {
      height: .4rem;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #c8c7cb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c7cb;
    border-radius: 10px;
  }
}

// animation marker links menu
@mixin animate($animation){
  animation: $animation 1s forwards;
}

@mixin keyframes($name){
  @keyframes #{$name}{
    @content;
  }
}