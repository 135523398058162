.login{
    position: relative;
    //width: 100vw;
    min-height: 100vh;
    background-image: url('../../images/login-bg.png');
    background-size: cover;
    &.register{
        background-image: url('../../images/register-bg.png');
    }
    &.code{
        background-image: url('../../images/code-bg.png');
    }
    .intro{
        /*top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 99vw;
        max-width: 950px;
        height: 100vh;*/
        @media (min-width: 768px){
            height: initial;
        }
        .container{
            padding: 0;
            .content{
                align-items: center;
                //background-color: #222222;
                padding: 0;
                .colLeft{
                    padding: 0;
                    margin: 0;
                    height: 30vh;
                    @media (min-width: 768px){
                        width: 45%;
                        height: initial;
                        position: relative;
                    }
                    h1{
                        color: #ffffff;
                        position: absolute; 
                        top: 66px; 
                        left: 45px; 
                        max-width: 320px;
                    }
                }
                .colRight{
                    position: relative;
                    padding-left: 4rem;
                    padding-right: 4rem;
                    //height: 65vh;
                    @media (min-width: 768px){
                        width: 55%;
                        padding-right: 100px;
                        height: 605px;
                        padding-top: 40px;
                    }                    
                    @media (min-width: 1024px){
                        padding-top: 95px;
                    }
                    p{
                        line-height: 20px;
                        margin-bottom: 20px;
                        &.error{
                            color: #FF0000;
                            font-size: 12px;
                            line-height: 17px;
                            margin-bottom: 0;
                        }
                        &.copyright{
                            display: none;
                            visibility: hidden;
                            opacity: 0;
                            font-size: 10px;
                            line-height: 14px; 
                            position: absolute; 
                            bottom: -20px;
                            a{
                                text-decoration: underline;
                                color: #05051E
                            }
                        }
                    }
                    .charts{
                        display: none;
                            visibility: hidden;
                            opacity: 0;
                        p{
                            margin-bottom: 0;
                            display: flex;
                            align-items: center;
                            gap: 50px
                        }
                        max-height: 100px;
                        overflow-y: scroll;
                        
                    }
                    .spin{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }    
                }
            }
        }
    }
    
}