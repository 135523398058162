@use '../helpers/variables' as *;
input, 
textarea,
select {
  appearance: none;
  background: none;
  box-shadow: none;
  outline: 0;
  border: 0;
  border: 1px solid $color3;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #137E84;
}

select {
  cursor: pointer;
  &::-ms-expand {
    display: none;
  }
}

fieldset {
  border:0;
  margin:0 0 20px;
  padding:0;
}

.formGroup {
  margin-bottom: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    position: absolute;
    display: block;
    margin-bottom: .8rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: left;
    color: $color4;
    padding-left: 1rem;
    margin-top: .3rem;
    top: -.3rem;
    left: .8rem;    
    &.error{
      position: absolute;
      color: $color2;
      font-size: 1rem;
      bottom: -2.5rem;
      top: initial;
    }
    &.checkbox{
      position: initial;
      color: #fff;
      a{
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  &.relative{
    position: relative;    
  }
  &.flex{
    display: flex;
    label{
      &.error{
        top: initial;
        bottom: -1.2rem;
      }
    }
    /*label{
      //position: initial;
    }*/
  } 
 
}

.formControl {
  width: 100%;
  height: 4.8rem;
  padding: 0 0 0 1.6rem;//1.2rem 1.6rem 1.4rem;
  background-color: $color1;
  color: $color4;
  border: solid 1px $color3;
  border-bottom: 2px solid #137E84;
  font-size: 1.6rem;
  font-weight: 400; 
  &.error{
    border-bottom: 2px solid $color2;
  }

  &::-webkit-input-placeholder {
    color: $color4;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
  }
  &::-moz-placeholder {
    color: $color4;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
  }
  &:-ms-input-placeholder {
    color: $color4;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
  }
  &:-moz-placeholder {
    color: $color4;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
  }   
}

/* checkbox */ 
input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border: 1px solid $color3;
  transform: translateY(-0.075rem);
  margin-right: 1rem;
  cursor: pointer;
  border-radius: 4px;
  padding: 0;
  margin: 0;

  &::before {
    content: "";//"\e90b";//.icon-check
    border-left: .3rem solid #444;
    border-bottom: .3rem solid #444;
    width: 1.6rem;
    height: .8rem;
    transform: scale(0);
    transition: 200ms transform ease-in-out;
  }

  &:checked {
    //background-color: $color2;

    &::before {
      content:"";
      border-left: .3rem solid #444;
      border-bottom: .3rem solid #444;
      width: 1.6rem;
      height: .8rem;
      transform: rotate(-45deg) scale(1);
    }

    /*& + label {
      //font-weight: $fw-bold;
    }*/
  }
}

// radios
input[type="radio"] {
  &:not(.radioQuiz) {
    display: none;

    & + label {
      position: relative;
      display: flex;
      
      &::before {
        content: '';
        display: block;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        border: 1px solid $color3;
        border-radius: 50%;
        margin-right: 1rem;
        cursor: pointer;
      }
    }

    &:checked + label {
      font-weight: $fw-bold;

      &::before {
        border: 1px solid $color2;
      }

      &::after {
        content: '';
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: .4rem;
        left: .4rem;
        background-color: $color2;
        border-radius: 50%;
      }
    }
  }
}

// custom select
.modSelect {
  position: relative;
  .formControl {
    font-size: 1.4rem;
    color: $color2;
  }
  >i {
    position: absolute;
    top: 1.3rem;
    right: 1.6rem;
    &::before {
      font-size: 1.1rem;
      color: $color4;
    }
    &.icon-location-icon-black {
      &::before {
        font-size: 2rem;
      }
    }
  }
  .list {
    >li {
      cursor: pointer;
    }
  }
}

// progress bar
.modProgress {
  position: relative;
  display: inline-block;
  background-color: $color3;
  height: .4rem;
  overflow: hidden;

  > span {
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color2;
    transition: width 0.5s ease-in;
  }
}

.countries{
  max-height: 30vh;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-width: 73vw;
  overflow-y: scroll;
  padding: .5rem .4rem;
  z-index: 50;
  span{
    color: $color4;
  }
  .country{
    display: flex;
    gap: 8px;
    margin: 8px 0;
    padding: 1.8rem .8rem;
    cursor: pointer;
    &.selected{
      background: $color2;
      border-radius: 4px;
      //padding: .4rem 0;
      span{
        color: #ffffff;
      }
    }
    &:hover{
      background: $color2;
      border-radius: 4px;
      //padding: .4rem 0;
      span{
        color: #ffffff;
      }
    }
  }  
  
}
.flag{
  border-radius: 100%;
  width: 2rem;//24px;
  height: 2rem;//24px;
  overflow: hidden;
  position: relative;
  img{
    max-width: initial;
    min-width: 3rem;//40px;      
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &.left{
    position: absolute;
    left: 20px;
    top: 1.8rem;//10px;
  }
}
.country-selector{
  input{
    padding-left: 5rem;
    padding-top: 1rem;
  }
}
.selectArrow{
    position: absolute;
    right: 20px;
    top: 20px;
    &.open{
      transform: rotate(180deg);
    }
}

.formGroup{
  &:has(label.error){
    //border-bottom: 2px solid $color2;
    input[type="text"], input[type="email"]{
      &.formControl{
        border-bottom: 2px solid $color2;
      }
    }
  }
}