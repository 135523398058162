@use '../helpers/variables' as *;

.comodin{
    position:fixed;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 145px;
    padding: 12px 0px;
    bottom: 0;
    right: 50%;
    margin-right: -72px;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
    background-color: $color2;
}
.comodin-gris{
    position:fixed;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 145px;
    padding: 12px 0px;
    bottom: 0;
    right: 50%;
    margin-right: -72px;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
    background-color: #CEDEE7;;
}
.icon-team{
    margin:5px 0 0 0;
}
.texto-team{
    color: #F2F5F7;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    margin:5px 0 0 6px;

}