.pagination{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    .btn{
        width: 4rem;
        height: 4rem;
        padding:0;
        &.btnPrimary{
            background-color: #5B19C4;
            border-color: #5B19C4;
            color: #fff;
        }
        &.btnSecondary{
            border: 1px solid #E5E5E9;
            background-color: #FFFFFF;
        }
    }
}