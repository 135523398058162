/* aspect ratio */
.ima {
	display:block;
	width:100%;
	position:relative;
	overflow:hidden;
	>img {
		width: 100%;
		position:absolute;
		top:0;
		left: 0;
	}
}

.f16x9 {
	padding-top:56.25%;
}
.f8x9 {
  padding-top: 112.5%;
}
.f9x9 {
  padding-top: 100%;
}