@use '../helpers/variables' as *;

.quiz{
    background-image: url('../../images/quiz-bg.png');
    background-size: cover;
    background-position: center ;

    &.final{
        background-image: url('../../images/finish-bg.png');
    }

    .quiz-title{
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: 1.2rem 1.9rem;
        background-color: #222222;        
        .left{
            display: flex;
            align-items: center;
            gap: 12px;
            .gp-name{
                font-weight: 700;
                font-size:24px;
            }
        }
        .right{
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }
        .ready{
            font-weight: 400;
            font-size:18px;
        }
        
    }

    .preview{
        background: rgba(255, 255, 2555, .9);
        border-radius: 0px;
        width: calc(100vw - 32px);
        position: absolute;
        bottom: 25vh;
        left: 50%;
        transform: translateX(-50%);
        
        .preview-wrapper{
            padding: 1.6rem;
        }
        .preview-title{
            color: #222222;
            font-size: 28px;
            line-height: 34px;
            font-weight: 700;
        }
        .preview-flagh{
            svg{
                width: 5.8rem;
                height: 4.6rem;
            }
        }
    }
    .preview-header{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .preview-content{
        margin-top:10px;
        margin-bottom: 15px;;
    }
    .preview-line{
        width: 30px;
        height: 15px;
        border-top: #EC0000 3px solid;
    }
    .preview-content-text{
        color:#222222;
    }
    .footer-quiz{
        position: absolute;
        width: 100%;
        bottom: 13vh;

        .bt-letsgo {
            display: flex;
            margin: 62px 36px 0px 36px;
            padding: 10px;
            height: 44px;
            align-items: center;
            justify-content: center;
            background-color: #EC0000;
            border-radius: 40px;
        }
        
        .text-bt-letsgo {
            vertical-align: middle;
            height: 26.5px;
        }
        
    }
    
    

    .timer{
        span{
            font-size: 10rem;
            display: block;
            text-align: center;
        }
    }
}
.quizes{
    background-image: url('../../images/dashboard-bg.png');
    background-size: cover;
    padding: 6.4rem 4rem;
    h2{
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 3.8rem;
        position: relative;
        margin-bottom: 4rem;
        &:after{
            content: '';
            width: 3rem;
            height: .24rem;
            background-color: $color2;
            position: absolute;
            bottom: -2rem;
            left: 0;
        }
    }
    .gps{
        display: flex;        
        gap: .9rem;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 4.8rem;
        .gp{
            //border: 2px solid $color8;
            border-radius: 6px 6px 0px 0px;
            background: rgba(255, 255, 255, 0.6);            
            border-bottom: 2px solid #137E84;
            box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
            width: 33%;
            padding: .8rem;
            .top{
                display: flex;
                .left{
                    width: 70%;
                }
                .right{
                    width: 30%;
                    display: flex;
                    justify-content: flex-end;
                    img{
                        width: 1.3rem;
                    }
                }
            }
            h2{
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #444444;
                min-width: 3.3rem;
                &:after{
                    content: '';
                    width: 3rem;
                    height: .24rem;
                    background-color: $color2;
                    position: absolute;
                    bottom: -3.4rem;
                    left: 0;
                }
            }
            span{
                color: #222222;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.2rem;
                sup{
                    font-size: .8rem;
                }
            }
        }
    }
}