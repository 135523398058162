@use '../helpers/variables' as *;
.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */
main.awards{
    background-image: url('../../images/teams-bg.png');
    background-size: cover;
    .wrapper-awards{
        background-image: url('../../images/awards-bg.png');
        background-size: cover;
        padding: 26.4rem 3.2rem 3.2rem 3.2rem;  
        min-height: calc(100vh - 6.8rem);  
        position: relative;
        .info{
            position: absolute;
            bottom: 4rem;
            max-width: calc(100vw - 8rem);
            &>h2{
                font-size: 2.6rem;
                position: relative;        
                font-weight: 700;
                font-size: 3.2rem;
                line-height: 3.8rem;
                margin-bottom: 4rem;
                &:after{
                    content: '';
                    width: 3rem;
                    height: .24rem;
                    background-color: $color2;
                    position: absolute;
                    bottom: -2rem;
                    left: 0;
                }
            }
        }
        &.country{
            min-height: 100vh; 
            background-image: url('../../images/awards2-bg.png');
        }
        &.team{
            min-height: 100vh; 
            background-image: url('../../images/awards3-bg.png');
        }
        &.draws{
            min-height: 100vh; 
            background-image: url('../../images/awards4-bg.png');
        }
    }
    .carousel{
        background: #fff; 
        padding: 4rem 0 2.7rem 0;   
        .carousel-item{
            background-color: #F5F9FB;
            width: 28.4rem;
            &.empty{
                visibility: hidden;
                //width: 4rem;
            }
            .item-wrapper{
                padding: 0 .4rem;
            }
            .top{
                img{
                    width: 100%;
                }
            }
            .bottom{
                padding: 1.6rem;
                min-height: 17.2rem;
                h3{                   
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.4rem;
                    color: $color2;
                    margin: 0;
                    sup{
                        vertical-align: text-top;
                    }
                }
                .flex{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: .8rem;
                    .laurel{
                        background-image: url('../../images/laurel.svg');
                        width: 4.8rem;
                        height: 4rem;
                        position: relative;
                        padding-right: 4rem;
                        span{
                            position: absolute;
                            color: #444;
                            font-family: 'SantanderHeadline';
                            font-weight: 700;
                            font-size: 2.4rem;
                            line-height: 2.4rem;
                            top: 0;
                            left: 50%;                     
                            transform: translateX(-50%);
                        }
                    }
                }
                p{
                    color: $color4;
                    font-size: 1.2rem;
                    line-height: 1.6rem;                    
                    &.subtitle{
                        font-weight: 400;
                        font-size: 1.4rem;
                        line-height: 2rem;
                        margin-bottom: .8rem;
                    }
                }
            }
            
        }
    }
}