@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;
html {
  min-height: 100%;
}

body {
  min-height: 100%;
  background-color: rgba($color3, 0.3);
  color: $color1;
  font-size: 1.6rem;
  font-family: $font1;
}

a:not(.btn) {
  color: $color2;
  text-decoration: none;

  &:hover {
    color: $color10;
  }
}

.modLinkBack {
  display: inline-block;
  
  .linkBack {
    display: flex;
    align-items: center;
    font-weight: $fw-medium;

    [class^="icon-"] {
      margin-right: .5rem;
      &::before {
        font-size: 1.4rem;
      }
    }
  }
}

.flex {
  @media (min-width: $screenSm){
    display: flex;
  }
}

.colLeft {
  @media (min-width: $screenSm){
    @include cols(6);
  }

  @media (min-width: $screenLg){
    @include cols(5);
    @include colsOffset(1);
  }
}

.colRight {
  @media (min-width: $screenSm){
    @include cols(6);
  }

  @media (min-width: $screenLg){
    @include cols(4);
  }
}

.panel {
  padding: 2rem;
  background-color: #fff;
  @media (min-width: $screenSm){
    padding: 3rem 6rem;
  }
}

.tag {
  display: flex;
  align-items: center;
  background-color: $color2;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0.3rem 1.2rem;

  [class^="icon-"] {
    margin-left: 1rem;

    &::before {
      font-size: 1rem;
      color: #fff;
    }
  }
}

.card {
  min-width: 26.2rem;
  height: 34.7rem;
  background-color: #fff;
  border: 1px solid $color3;
  padding: 2rem;
  @media (min-width: $screenSm){
    width: 49%;
  }
  @media (min-width: $screenLg){
    width: 23.5%;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
    @media (min-width: $screenSm){
      margin: 0 2% 2% 0;
    }
  }

  &:nth-of-type(2n) {
    @media (min-width: $screenSm){
      margin-right: 0;
    }
    @media (min-width: $screenLg){
      margin-right: 2%;
    }
  }

  &:nth-of-type(4n) {
    @media (min-width: $screenLg){
      margin-right: 0;
    }
  }
}

.tooltip {
  width: 22.4rem;
  height: 0;
  position: absolute;
  z-index: 90;
  background-color: #fff;
  box-shadow: 0 0 9px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: height .3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 4rem;
    border-bottom: 8px solid #fff;
    border-top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  &.open {
    height: auto;
    overflow: visible;
  }
}