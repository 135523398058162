@use '../helpers/variables' as *;

.progressBar{
    display: flex;
    background-color: $color2;
    color: white;
    height: 10px;
    border-radius: 6px;
    margin:0px 16px 28px 16px;
}
.tramo-inicial{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    flex:1;
}
.tramo{
    flex:1;
}
.tramo-final{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    flex:0;
}
.tramo-blanco{
    background-color: white;
}
.tramo-rojo{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $color2;
}
.bolita{
    display: block;
    position:relative;
    margin:1px 1px 0 1px;
    background: $color1;
    border:1px solid $color5;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}
.activa{
    margin: -3px 0 0 -1px;
    background: $color1;
    border: 4px solid #768E9B;
    width: 16px;
    height: 16px;
}
.fallo{
    background: $color12;
    border:0px solid $color12;
}
.acierto{
    background: $color11;
    border:0px solid $color11;
}