@use '../helpers/variables' as *;
//@import url("https://use.typekit.net/jsh0cas.css");

@font-face {
  font-family: 'SantanderText';
  font-style: normal;
  font-weight: 900;  
  src: local(''),       
       url('../../fonts/SantanderMicroTextExtraBold.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: italic;
  font-weight: 900;  
  src: local(''),       
       url('../../fonts/SantanderMicroTextXBd-It.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: normal;
  font-weight: 700;  
  src: local(''),       
       url('../../fonts/SantanderMicroText-Bd.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: italic;
  font-weight: 700;  
  src: local(''),       
       url('../../fonts/SantanderMicroText-BdIt.woff') format('font-woff'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: normal;
  font-weight: 500;  
  src: local(''),       
       url('../../fonts/SantanderMicroTextSemiBold.woff') format('font-woff'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: italic;
  font-weight: 500;  
  src: local(''),       
       url('../../fonts/SantanderMicroTextSmBd-It.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: normal;
  font-weight: 400;  
  src: local(''),       
       url('../../fonts/SantanderMicroText.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: italic;
  font-weight: 400;  
  src: local(''),       
       url('../../fonts/SantanderMicroText-It.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: normal;
  font-weight: 200;  
  src: local(''),       
       url('../../fonts/SantanderMicroText-Lt.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderText';
  font-style: italic;
  font-weight: 200;  
  src: local(''),       
       url('../../fonts/SantanderMicroText-LtIt.woff2') format('woff2'); /* Modern Browsers */       
}

/*SantanderHeadline*/
@font-face {
  font-family: 'SantanderHeadline';
  font-style: normal;
  font-weight: 200;  
  src: local(''),       
       url('../../fonts/SantanderHeadlineLight.woff') format('font-woff'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderHeadline';
  font-style: italic;
  font-weight: 200;  
  src: local(''),       
       //url('../../fonts/SantanderHeadlineLight-It.woff2') format('woff2'),
       url('../../fonts/SantanderHeadlineLight-It.woff') format('font-woff'); /* Pretty Modern Browsers */ /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderHeadline';
  font-style: normal;
  font-weight: 400;  
  src: local(''),       
       url('../../fonts/SantanderHeadline-Regular.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderHeadline';
  font-style: italic;
  font-weight: 400;  
  src: local(''),       
       url('../../fonts/SantanderHeadline-It.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderHeadline';
  font-style: normal;
  font-weight: 700;  
  src: local(''),       
       url('../../fonts/SantanderHeadline-Bold.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'SantanderHeadline';
  font-style: italic;
  font-weight: 700;  
  src: local(''),       
       url('../../fonts/SantanderHeadline-BoldIt.woff2') format('woff2'); /* Modern Browsers */       
}

@font-face {
  font-family: 'Formula1';
  font-style: normal;
  font-weight: 900;  
  src: local(''),       
       url('../../fonts/Formula1-Black.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'Formula1';
  font-style: normal;
  font-weight: 700;  
  src: local(''),       
       url('../../fonts/Formula1-Bold.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'Formula1';
  font-style: italic;
  font-weight: 400;  
  src: local(''),       
       url('../../fonts/Formula1-Italic.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'Formula1';
  font-style: normal;
  font-weight: 400;  
  src: local(''),       
       url('../../fonts/Formula1-Regular.woff2') format('woff2'); /* Modern Browsers */       
}
@font-face {
  font-family: 'Formula1Wide';
  font-style:normal;
  font-weight: 900;  
  src: local(''),       
       url('../../fonts/Formula1-Wide.woff2') format('woff2'); /* Modern Browsers */       
}



input,
select,
textarea, 
button {
  font-family: $font1;
}

h1, h2, h3{
  font-family: $font2;
}