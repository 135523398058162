/* BREAKPOINTS */
/* 480px */
$containerXs: 48rem;
$screenXs: 30em;
/* 768px */
$containerSm: 76.8rem;
$screenSm: 48em;
/* 992px */
$containerMd: 99.2rem;
$screenMd:62em;
/* 1200px */
$containerLg: 120rem;
$screenLg:75em;
/* 1600px */
$containerXlg: 160rem;
$screenXlg:100em;


/* colors */
$color1: #ffffff;//#00304B;/* #05051E;*/
$color2: #EC0000;
$color3: #DEEDF2;
$color4: #444444;
$color5: #cccccc;
$color6: #0A0B09;
$color7: rgba(91,25,196,0.2);
$color8: #cccccc;
$color9: #05051E;
$color10: #A32DC1;
$color11: #137E84;
$color12: #CC0000;
$color13: #F2F8FA;
$color14: #222222;



/* fonts */
$font1: 'SantanderText', sans-serif;
$font2: 'SantanderHeadline', sans-serif;

$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 700;
