
@use '../helpers/variables' as *;
.spinner{
    height: 5rem;
    width: 5rem;
    margin: 0 auto;
    padding: 25rem 0;
    background-color: $color2;// #5B19C4;
    -webkit-mask: url(../../images/audio.svg) no-repeat center;
    mask: url(../../images/audio.svg) no-repeat center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.spinner-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(118, 140, 155, 0.5);
    backdrop-filter: blur(4px);
    z-index: 200;
}