@use '../helpers/variables' as *;
main.intro{
    background-image: url('../../images/intro-bg.png');
    background-size: cover;
    padding: 0 2.4rem;
    &.intro-2{
        background-image: url('../../images/intro2-bg.png');
    }
    &.intro-3{
        background-image: url('../../images/intro3-bg.png');
    }
    &.intro-4{
        background-image: url('../../images/intro4-bg.png');
    }
    .wrapper-intro{
        padding: 3.2rem 1.6rem 1.6rem 1.6rem;
        background: rgba(0, 0, 0, .7);
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
        border-radius: 20px 20px 0px 0px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 4.8rem);
        bottom: 0;
        display: flex;
        flex-direction: column;  
        gap: 2.8rem;      
        &>h2{
            font-size: 2.6rem;
            position: relative;        
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 4rem;
            &:after{
                content: '';
                width: 3rem;
                height: .24rem;
                background-color: $color2;
                position: absolute;
                bottom: -2rem;
                left: 0;
            }
        }
        &>img{
            //max-width: 5.3rem;
            min-height: 5rem;
            height: 5rem;
            max-height: 5rem;
            margin: 0 auto;
            display: block;
        }
        &>p{
            min-height: 6.6rem;
        }
        ul{
            display: flex;
            justify-content: center;
            gap: .8rem;
            li{
                width: .8rem;
                height: .8rem;
                background-color: rgba(255, 255, 255, 0.4);
                border-radius: 50px;
                transition: all .3s;
                &.active{
                    width: 2.4rem;
                    background-color: rgba(255, 255, 255, 1);
                }
            }
        }
        .buttons{
            display: flex;
            justify-content: space-between;
            .skip{
                color: rgba(255, 255, 255, 0.7);
                font-family: 'SantanderHeadline';
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.4rem;
            }
            .timer{
                position: relative;
                &>div{
                    height: 5.8rem;
                }
            }
            .next{
                width: 4.2rem;
                height: 4.2rem;
                background-color: #fff;
                position: absolute;
                border-radius: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
                }
            }
        }
    }
}