@use '../helpers/variables' as *;
@use '../helpers/mixins' as *;
.quiz {
  .stepper {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    @media (min-width: $screenSm){
      padding: 2rem;
    }

    .step {
      display: flex;
      align-items: center;
      @media (min-width: $screenSm){
        padding: 0 .5rem;
      }
      
      &:not(:last-of-type) {
        margin-right: 1rem;
        @media (min-width: $screenSm){
          flex-grow: 1;
        }
  
        &::after {
          @media (min-width: $screenSm){
            content: '';
            width: 100%;
            height: .1rem;
            background-color: $color1;
          }
        }
      }

      .number {
        display: block;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        font-size: 1.6rem;
        line-height: 3.8rem;
        font-weight: $fw-bold;
        border-radius: 50%;
        border: 1.5px solid $color1;
        text-align: center;
      }

      label {
        display: none;
        margin: 0 1rem;
        @media (min-width: $screenSm){
          display: block;
          margin: 0 1.5rem;
        }
      }

      &.active {
        flex-grow: 1;
        color: $color2;

        .number {
          border: 2px solid $color2;          
        }

        label {
          display: block;
          font-weight: $fw-bold;
        }

        &::after {
          content: '';
          width: 100%;
          height: .2rem;
          background-color: $color2;
        }
      }

      &.completed {
        flex-grow: 1;
        color: $color2;

        .number {
          border: 2px solid $color2;
          background-color: $color2;
          position: relative;
          &::after{
            font-family: 'dm';
            content: "\e90b";//.icon-check
            color: #ffffff;
            font-size: 1.2rem;
            font-size: 1.2rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        label {
          display: block;
          font-weight: $fw-bold;
        }

        &::after {
          content: '';
          width: 100%;
          height: .2rem;
          background-color: $color2;
        }
      }
    }
  }

  .panelContent {
    margin: 2rem 0;
  }

  .question {
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: $fw-bold;
    margin-bottom: 2rem;
  }

  .modAnswer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .answer {
      .radioQuiz {
        display: none;
      }

      &.answerSwitch {
        &:first-of-type {
          margin-right: 1.6rem;
        }

        label {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 12.8rem;
          height: 14rem;
          border: 1px solid $color3;
          cursor: pointer;
          transition: all .1s ease-in-out;

          &.selected,
          &:hover {
            border: 2px solid $color2;

            > span {
              &.roundIcon {
                &::before {
                  border-color: $color2;
                }
    
                &::after {
                  border-color: $color2;
                }

                [class^="icon-"] {
                  &::before {
                    color: $color2;
                  }
                }
              }
            }
          }

          > span {
            display: block;
            margin: 1rem 0;

            &.roundIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 4.5rem;
              min-width: 4.5rem;
              height: 4.5rem;
              position: relative;
              border-radius: 50%;
    
              &::before {
                content: '';
                width: 4.5rem;
                height: 4.5rem;
                border: 1.5px dashed $color1;
                border-radius: 50%;
                position: absolute;
                clip-path: inset(0 2.25rem 0 0);
              }

              &::after {
                content: '';
                width: 4.5rem;
                height: 4.5rem;
                border: 1.5px solid $color1;
                border-radius: 50%;
                position: absolute;
                clip-path: inset(0 0 0 2.25rem);
              }

              [class^="icon-"] {
                &::before {
                  font-size: 1.8rem;
                }
              }

              .icon-check {
                &::before {
                  font-size: 1.6rem;
                }
              }
            }

            &.desc {
              font-size: 1.8rem;
              font-weight: $fw-bold;
            }
          }
        }
      }

      &.answerList {
        flex-basis: 100%;
        
        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }

        label {
          display: flex;
          align-items: center;
          height: 5.4rem;
          padding: 1rem;
          border: 1px solid $color3;
          font-size: 1.6rem;
          font-weight: $fw-bold;
          cursor: pointer;
          transition: all .1s ease-in-out;

          &.selected,
          &:hover,
          &:focus {
            border-color: $color2;

            .word {
              /*background-color: $color2;*/
              border-color: $color2;
              /*color: #fff;*/
            }
          }
          &.selected{
            .word {
              background-color: $color2;
              border-color: $color2;
              color: #fff;
            }
          }

          .word {
            display: block;
            width: 3.2rem;
            height: 3.2rem;
            line-height: 3rem;
            border: 1px solid $color9;
            text-align: center;
            margin-right: 1.6rem;
            transition: all .1s ease-in-out;
          }
        }
      }
    }
  }

  .btnNext {
    width: auto;
    margin: 3rem auto;
  }

  &.result {
    padding: 2rem 0;

    .panelContent {
      > .top {
        margin: 4rem 0 2rem;
        text-align: center;

        .title {
          font-size: 2rem;
          margin-bottom: 1.25rem;
        }
        .subtitle{
          font-size: 2.4rem;
          line-height: 3.2rem;
        }

        .category {
          font-size: 1.4rem;
          text-transform: uppercase;
          font-weight: $fw-bold;
        }
      }
      > .content{
        p{
          font-size: 1.6rem;
          line-height: 2.8rem;
          text-align: center;
          max-width: 75rem;
          margin: 0 auto;
          padding: 4rem 0;
          border-bottom: 1px solid #E5E5E9;
        }
      }
      > .contact{
        p{
          font-size: 1.6rem;
          line-height: 2.8rem;
          text-align: center;
          max-width: 75rem;
          margin: 0 auto;
          padding: 4rem 0;
        } 
        > .links{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 32px;
          a{
            span{
              margin-right: 1.2rem;
              color: #5B19C4;
              font-size: 2rem;
            }
          }
        }       
      }
    }
    
    .modChart {
      @media (min-width: $screenSm){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .cta {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .modResult {
      @media (min-width: $screenSm){
        @include cols(5)
      }
    }

    .radar {
      @media (min-width: $screenSm){
        @include cols(7)
      }
    }

    .list {
      margin-bottom: 4rem;
      @media (min-width: $screenSm){
        margin-bottom: 0;
      }

      > li {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }
    }

    .tit {
      width: 40%;
      font-weight: $fw-bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .perc {
      width: 15%;
      margin: 0 1rem;
    }

    .modProgress {
      width: calc(45% - 2rem);
    }
  }
}