*, *::after, *::before {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
  margin:0;
}

a {
	text-decoration: none;
	cursor:pointer;
	transition: all .3s ease-in-out;
	&:hover,
	&:focus,
	&:active {
    outline: 0;
    text-decoration: none;
	}
}

ul, ol {
	list-style:none;
	padding:0;
	margin:0;
}

p, h1 {
	margin:0;
	padding:0;
}

img {
	max-width:100%;
}

button {
  cursor: pointer;
  &:focus {
    outline:0;
    border:0;
  }
}



