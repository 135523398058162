@use '../helpers/variables' as *;
.footer {
  background: $color2;
  padding: 3.2rem 2.4rem;
  .flex {
    display: flex;
    flex-direction: column;
    //height: 8rem;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
    &>img{
      width: 12rem;
      margin: 0 auto;
      display: block;
    }
  }

  p,
  a {
    font-style: normal;
    font-weight: 200;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $color1;
  }
  p{
    text-align: center;
  }

  a {
    font-weight: $fw-medium;
  }
  .rrss{
    ul{
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 2rem;
      li{
        a{
          height: 100%;
          display: flex;
          align-content: center;;
        }
      }
    }
  }
}