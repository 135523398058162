@use '../helpers/variables' as *;

button {
  background: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  padding: 0;
}

.btnIcon {
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.btn {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 1rem 2.5rem;
  border: 2px solid transparent;
  font-size: 1.6rem;
  font-weight: 700;//$fw-medium;
  line-height: 2.4rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  @media (min-width: $screenSm) {
    width: auto;
  }

  &.btnPrimary {
    background-color: $color2;
    border: 2px solid $color2;
    color: $color1; //#fff;
    border-radius: 40px;    

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $color2;
      border: 2px solid $color2;
      color: $color1;
    }
  }

  &.btnSecondary {
    color: $color2;
    border: 2px solid $color2;

    &:not([disabled]):hover {
      background-color: $color2;
      border: 2px solid $color2;
      color: #fff;
    }
  }

  &.btnLink {
    width: auto;
    display: flex;
    align-items: center;
    color: $color2;
    font-weight: $fw-medium;
    padding: 1rem 0;
    border: 0;
    margin: 0;
    padding: 0;
    height: auto;

    &:hover {
      color: $color10;
    }

    [class^="icon-"] {
      &::before {
        font-size: 2rem;
      }
    }
    img{
      margin-left: 5px;
      margin-bottom: -3px;
    }
  }

  &[disabled] {
    opacity: 0.4;
    color: rgba(255,255,255,0.4);
    cursor: auto;
  }

  [class^="icon-"] {
    margin-right: 1rem;
  }
}

