@use '../helpers/variables' as *;
.dropdown {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 250;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(4, 30, 48, 0.2);
  @media (min-width: $screenMd) {
    width: auto;
    left: auto;
    right: 0;
    z-index: 150;
  }
  .title {
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    padding: 1.5rem 2rem;
  }
  .dropdown-header{
    font-size: 16px;
    color: #000000;
    font-weight: bold;  
    line-height: 24px;
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid #E5E5E9;
  }
  .dropdown-content{
    padding: 24px;
  }
}
