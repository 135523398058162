@use '../helpers/variables' as *;

.chrono-reloj{
   vertical-align: middle;
}
.chrono-tiempo{
    margin-left: 8px;
    font-weight: 700;
}
.chrono-segundos{
    font-size:24px;
    
}
.chrono-milesimas{
    font-size:20px;
}