@use '../helpers/variables' as *;
.header {
  height: 6.8rem;
  //background-color: #fff;
  background: linear-gradient(90deg, #CC0000 0%, #EC0000 100%);
  //border-bottom: 0.1rem solid $color3;
  @media (min-width: $screenSm){
    height: 11.4rem;
  }

  .flex {
    height: 6.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;//space-between;
    gap: 12px;
    @media (min-width: $screenSm){
      height: 11.4rem;
    }
    .left{
      width: 5%;
    }
    .right{      
      width: 90%;
      display: flex;
      justify-content: center;
      div+div{
        position: relative;
        margin-left: 2rem;
        &:before{
          content: '';
          position: absolute;
          left: -1rem;
          top: 0;
          width: 1px;
          height: 1.8rem;
          background-color: #fff;
        }
      }
      .awards{
        display: flex;
        align-items: center;
        gap: 1.5rem;
        h2{
          font-size: 3.2rem;
          line-height: 3.8rem;
          margin: 0;
        }
        .awards-logo{
          display: block;
          width: 3rem;          
          height: 2rem;
          background-color: #fff;
          -webkit-mask: url(../../images/awards.svg) no-repeat center;
          mask: url(../../images/awards.svg) no-repeat center;
        }
      }
    }
  }

  .logo {
    height: 1.8rem;
    @media (min-width: $screenSm){
      height: 4.6rem;
    }
  }
  .burger{
    min-width: 2.4rem;
  }  
}
.menu{
  position: absolute;
  z-index: 100;
  height: calc(100vh - 10rem);
  background: #fff;
  width: 90vw;
  left: 10vw;  
  ul{
    li{
      padding: 2rem 2.8rem;
      &.active{
        border-left: 4px solid $color2;
      }
      a{
        display: flex;
        align-items: center;
        gap: 2rem;
        span{
          color: #444;
        }
      }
    }
    &.menu-footer{
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: row;
      background: #F5F9FB;
      width: 100%;
      li{
        width: 50%;
        border: 1px solid #CCCCCC;
        justify-content: center;
        &:nth-child(1){
          padding-left: 5rem;
        }
        &:nth-child(2){
          padding-right: 5rem;
        }
        button{
          display: flex;
          align-items: center;
          gap: 2rem;
        }
      }
    }
  }  
}