@use '../helpers/variables' as *;
.dashboard {
  padding: 4rem 0;

  .headerDashboard {
    @media (min-width: $screenSm){
      display: flex;
      justify-content: flex-end;
    }

    .btn {
      margin: 0 0 2rem;
      @media (min-width: $screenSm){
        margin: 0 0 0 2rem;
      }
    }
  }

  .content {
    position: relative;
    padding: 2rem 0;

    > .top {
      margin: 2rem 0;
      @media (min-width: $screenSm){
        display: flex;
        justify-content: space-between;
      }

      .title {
        font-size: 2rem;
        font-weight: $fw-bold;

        > span {
          font-weight: $fw-normal;
        }
      }

      .ctas {
        display: flex;
        div:first-of-type {
        .btnLink {
          /*&:first-of-type {*/
            margin-right: 2rem;
          }
        }
      }
    }

    .left {
      @media (min-width: $screenLg){
        display: flex;
        align-items: center;
      }
    }

    .right {
      width: 100%;
      position: absolute;
      top: 7.5rem;
      @media (min-width: $screenSm){
        width: auto;
        position: static;
      }
    }
  }

  .typeOrder {
    color: $color5;
  }

  .tags {
    padding-top: 5rem;
    @media (min-width: $screenSm){
      padding-top: 0;
    }
    @media (min-width: $screenLg){
      overflow-x: auto;
      margin: 0 2rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      > li {
        margin: 0 1rem 1rem 0;
        @media (min-width: $screenLg){
          margin: 0 .25rem;
        }
      }
    }
  }

  .charts {
    @media (min-width: $screenSm){
      display: flex;
      flex-wrap: wrap;
    }
  }

  .card {
    .top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 1.2rem;

      .title {
        font-size: 1.6rem;
        font-weight: $fw-bold;
      }
    }

    .btnEdit {
      [class^="icon-"] {
        &::before {
          color: $color2;
          font-size: 1.8rem;
        }
      }
    }

    .category {
      background-color: $color1;
      color: #fff;
      font-size: 1.2rem;
      padding: .2rem 1.2rem;
    }

    .date {
      font-size: 1.2rem;
      color: $color5;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $color3;
      padding: 1rem 0;

      > p {
        width: 49%;
        text-align: center;
      }

      .tit {
        display: block;
        color: $color5;
        font-size: 1.2rem;
        margin-bottom: .25rem;
      }

      .num {
        display: block;
        font-size: 1.4rem;
        font-weight: $fw-bold;
      }
    }
    svg{
      .apexcharts-legend-series,
      g[seriesName="Borramex2"],
      .apexcharts-legend-series[seriesname="Borramex2"]{
          display: none!important;
      }
      g{
        &.apexcharts-radar-series{
          polygon:nth-child(1){
            fill: rgba(229,229,233,0.2);
          }
        }
        .apexcharts-xaxis{
            /*text{
                font-family: Halcom;
                font-size: 10px;
                fill: #000;
            }*/
            text:nth-child(2){
                position: absolute;
                transform: rotate(90deg);
                /*transform-origin: 73px -5px;
                @media (min-width: 1600px){
                  transform-origin: 104px -5px;
                }*/
            }
            text:nth-child(4){
                position: absolute;
                transform: rotate(-90deg);
                /*transform-origin: -92px -21px;
                @media (min-width: 1600px){
                  transform-origin: -117px -21px;
                }*/
            }
        }
        /*.apexcharts-yaxis{
            text{
                font-family: Halcom;
                font-size: 8px;
                fill: #000000;
            }
        }*/
      }
    }
    .top+.top+div{
      min-height: initial!important;
      margin-top: -40px;
      margin-bottom: -40px;
    }
    /*.apexcharts-canvas{
      
    }*/
  }

  .filters,
  .sort {
    position: relative;
  }

  .tooltipFilters {
    right: 0; 

    .tit {
      font-size: 1.4rem;
      line-height: 1;
      font-weight: $fw-bold;
      padding: 1.6rem 2rem;
      border-bottom: 1px solid $color3;
    }

    .list {
      padding: 2rem;

      > li {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }

      label {
        font-size: 1.4rem;
      }
    }
  }
}