@use '../helpers/variables' as *;
.tooltip {
    width: 22.4rem;
    height: 0;
    position: absolute;
    z-index: 90;
    background-color: #fff;
    box-shadow: 0 0 9px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: height .3s ease-in-out;
  
    &::before {
      content: '';
      position: absolute;
      top: -8px;
      right: 4rem;
      border-bottom: 8px solid #fff;
      border-top: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  
    &.open {
      height: auto;
      overflow: visible;
    }
  
    &.tooltipFilters {
      right: 0; 
  
      .tit {
        font-size: 1.4rem;
        line-height: 1;
        font-weight: $fw-bold;
        padding: 1.6rem 2rem;
        border-bottom: 1px solid $color3;
      }
  
      .list {
        padding: 2rem;
  
        > li {
          display: flex;
          align-items: center;
  
          &:not(:last-of-type) {
            margin-bottom: 1rem;
          }
        }
  
        label {
          font-size: 1.4rem;
        }
      }
    }
  
    &.tooltipCopy {
      width: auto;
      bottom: 15rem;
  
      &::before {
        content: none;
      }
  
      > p {
        color: $color2;
        font-weight: $fw-bold;
        padding: 2rem;
      }
    }
  }